/* ebro main JS */
const FE = {

    MobileMenu: {
        init: function() {
            const burgerIcon = document.getElementById('burger-icon');
            const navMain = document.getElementById('nav-main');
            burgerIcon.addEventListener("click", function () {
                if ( navMain.classList.contains('show') ) {
                    this.setAttribute('aria-expanded', 'false');
                    navMain.classList.remove('show');
                    this.classList.remove('active');
                } else {
                    this.setAttribute('aria-expanded', 'true');
                    navMain.classList.add('show');
                    this.classList.add('active');
                }
            });
            this.markSubMenu(navMain.querySelectorAll(':first-child > li'));
        },
        markSubMenu: function (menuItems) {
            menuItems.forEach(function (menuItem) {
                //console.log(menuItem);
                if ( menuItem.lastChild.nodeName === 'UL' ) {
                    const subMenuMarker = document.createElement('button');
                    subMenuMarker.addEventListener('click', FE.MobileMenu.subMenuOpenHandler);
                    menuItem.firstChild.appendChild( subMenuMarker );
                    //console.log('we need to add a sub menu');
                }
            });
        },
        subMenuOpenHandler: function (e) {
            e.preventDefault();
            this.classList.toggle('active');
            this.parentElement.nextElementSibling.classList.toggle('open')
            //console.log(this);
        }
    },

    Lightbox: {
        init: function () {
            const parvus = new Parvus({
                captionsAttribute: 'alt'
            });
        }
    },

    ImageSlider: {
        init: function () {
            var sliderContainer = document.querySelector('.page-id-14 .content-main .col-75 .ce-gallery');
            var slides = document.querySelectorAll('.page-id-14 .content-main .col-75 .ce-gallery .ce-row');
            //console.log(sliderContainer, slides);
            this.setupSliderMarkup(sliderContainer, slides, true, true);
        },
        setupSliderMarkup: function (sliderContainer, slides, controls = false, bullets = false) {
            var sliderEl = document.createElement('div');
            var sliderTrack = '<div class="glide__track" data-glide-el="track"></div>';
            var sliderControls = '<div class="glide__arrows" data-glide-el="controls"><button class="glide__arrow glide__arrow--left" data-glide-dir="<">prev</button><button class="glide__arrow glide__arrow--right" data-glide-dir=">">next</button></div>';

            var slidesCount = slides.length;
            for (var i = 0; i < slidesCount; i++) {
                slides[i].classList.add('glide__slide');
            }

            sliderEl.classList.add('glide');
            sliderContainer.parentNode.insertBefore(sliderEl, sliderContainer);
            sliderContainer.classList.add('glide__slides');

            var sliderInnerContent = sliderTrack;

            // add controls optional
            if (controls === true) {
                sliderInnerContent += sliderControls;
            }

            // add bullets optional
            if (bullets === true) {
                let sliderBulletsHtml = '';
                for (let i = 0; i < slidesCount; i++) {
                    sliderBulletsHtml += '<button class="glide__bullet" data-glide-dir="=' + i + '"></button>';
                }
                sliderInnerContent += '<div class="glide__bullets" data-glide-el="controls[nav]">' + sliderBulletsHtml + '</div>';
            }

            sliderEl.innerHTML = sliderInnerContent;

            var sliderTrackEl = sliderEl.querySelector('.glide__track');
            sliderTrackEl.appendChild(sliderContainer);

            new Glide('.page-id-14 .glide', {
                type: 'carousel',
                autoplay: 4000,
                hoverpause: true
            }).mount();
        }
    },

    ModalBox: {
        init: function () {
            var modalBoxArea = document.querySelector('.pv-modal-box-area');
            if ( modalBoxArea.dataset.open === 'auto') {
                modalBoxArea.classList.add('show');
            }
            document.querySelector('.pv-modal-box .close').addEventListener('click', function (e) {
                e.preventDefault();
                modalBoxArea.classList.remove('show');
            });
        }
    }
}


window.addEventListener("DOMContentLoaded", function () {

    FE.Lightbox.init();
    FE.MobileMenu.init();

    if ( document.querySelector('.pv-modal-box .ce-gallery') ) {
        FE.ModalBox.init();
    }

    if ( document.querySelectorAll('.page-id-14 .content-main .col-75 .ce-gallery .ce-row').length > 1 ) {
        FE.ImageSlider.init();
    }
});
